export const deepcopy = <T>(
  obj: T,
  errMessage = 'Error deepcopying object'
): T => {
  try {
    return JSON.parse(JSON.stringify(obj))
  } catch (e) {
    throw new Error(`
      ${errMessage}
      ${obj}
    `)
  }
}
