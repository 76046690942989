import { AD_BOX_SELECTOR } from '../../constants'
import { writeCss } from '../../helpers/DOM'
import { checkElementInsertedBelowViewport } from '../../helpers/DOM/isBelowViewport'
import { doOnce } from '../../helpers/doOnce'
import Logger from '../../helpers/Logger'
import type WebModel from '../../models/WebModel'
import { adLabelColor } from '../../styles/base'
import type { Slot } from '../Slot'

export class AdBoxConfig {
  private _heightPxToContain: number = 0
  widthStyle: string

  constructor({
    heightPxToContain,
    widthStyle
  }: {
    heightPxToContain: number
    widthStyle: string
  }) {
    this.heightPxToContain = heightPxToContain
    this.widthStyle = widthStyle
  }

  get heightPxToContain(): number {
    return this._heightPxToContain
  }
  set heightPxToContain(value: number) {
    if (value > this._heightPxToContain) {
      this._heightPxToContain = value
    }
  }
}

const AD_LABEL_BUFFER_PX = 24
/**
 * Surrounds slot wrapper to provide a fixed decorative "canvas" for
 * ads to display.
 * @param config
 */
export const hasAdBox = (target: Constructor<Slot>): any =>
  class extends target {
    private _adBox: HTMLElement
    labelBuffer = 0

    constructor(...args) {
      super(...args)

      if (!this.model.ad_box) {
        return
      }

      if (this.model.ad_box_placeholder_text) {
        this.labelBuffer = AD_LABEL_BUFFER_PX
      }

      this.restrictRefreshSize = false
    }

    template() {
      if (!this.adBoxConfig) {
        throw Error('AdBox Configuration not implemented!')
      }

      if (!this.model.ad_box) {
        return super.template()
      }

      writeAdBoxStyles(this.adBoxConfig, this.model, this.labelBuffer)
      const template = super.template()

      const { heightPxToContain, widthStyle } = this.adBoxConfig
      const heightStyle = `${heightPxToContain + this.labelBuffer}px`

      return adBoxTemplate(template, this.id, heightStyle, widthStyle)
    }

    get adReporterAnchor(): HTMLElement {
      return this.model.ad_box ? this.adBox : this.wrapper
    }

    get adBox(): HTMLElement {
      if (!this._adBox) {
        this._adBox =
          document.querySelector(
            `.${AD_BOX_SELECTOR}[data-slotid="${this.id}"]`
          ) || document.createElement('div')
      }
      return this._adBox
    }

    getSlotDom() {
      if (!adBoxIsEnabled(this.model)) {
        return super.getSlotDom()
      }
      return this.adBox
    }

    blockRenderForCLS() {
      if (!adBoxIsEnabled(this.model) || !this.adBoxConfig || !this.target) {
        return super.blockRenderForCLS()
      }

      try {
        const insertedBelowViewport = checkElementInsertedBelowViewport(
          this.target,
          this.insertPosition,
          this.adBoxConfig.heightPxToContain
        )
        const insertedInsideTarget =
          this.insertPosition === 'afterbegin' ||
          this.insertPosition === 'beforeend'
        const insertTarget = insertedInsideTarget
          ? this.target
          : (this.target.parentElement as HTMLElement)
        const targetHasReservedSpace =
          !!getComputedStyle(insertTarget).minHeight
        return (
          !(insertedBelowViewport || targetHasReservedSpace) ||
          super.blockRenderForCLS()
        )
      } catch (e) {
        Logger.debug(
          'Error computing render block. Defaulting to allow render.'
        )
        return false
      }
    }
  }

const writeAdBoxStyles = doOnce(
  (config: AdBoxConfig, model: WebModel, labelBuffer: number) =>
    writeCss(getAdBoxStyles(config, model, labelBuffer))
)

const contentDots = '\\00B7\\00B7\\00B7'

const getAdBoxStyles = (
  config: AdBoxConfig,
  { ad_box_bg_color, ad_box_placeholder_text }: WebModel,
  labelBuffer: number
) => `
  body .adunitwrapper[style*='display: block']>.adunit:before {
    display: none!important;
  }
  .${AD_BOX_SELECTOR} {
    justify-content: center;
    position: relative;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${ad_box_bg_color};
    overflow: hidden;
    padding-top: ${labelBuffer}px
  }

  .${AD_BOX_SELECTOR} .remove_padding {
    /* 
      This trick is incompatible with ad box when overflow 
      is applied. 
    */
    margin-left: 0px;
    margin-right: 0px;
  }

  .${AD_BOX_SELECTOR} > div {
    z-index: 1;
  }

  @keyframes fadeOut {
    0% { opacity: 1;}
    100% { opacity: 0.01 }
  }

  .${AD_BOX_SELECTOR}:before {
    content: "ADVERTISEMENT";
    position: absolute;
    display: ${ad_box_placeholder_text ? 'flex!important' : 'none'};
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 100%;
    text-align: center;
    color: ${adLabelColor};
    font-size: 11px;
    letter-spacing: 2px;
    top: 2px
  }

  .${AD_BOX_SELECTOR}:after {
    content: "${contentDots}";
    position: absolute;
    display: flex!important;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    text-align: center;
    color: #C4C4C4;
    font-size: 62px;
    letter-spacing: 2px;
    z-index: 0;
    animation: fadeOut 1s linear 0s infinite;
    animation-direction: alternate;
  }

  .${AD_BOX_SELECTOR} .adunitlabel {
    width: 100%!important;
    left: 0; 
    bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
  }

  .${AD_BOX_SELECTOR} .adunitwrapper {
    position: static;
    margin: 0 !important;
    display: flex;
    flex-direction: column;
  }

  .${AD_BOX_SELECTOR} [data-slotid="recipe_btf"] {
    float: right;
  }
  
  .${AD_BOX_SELECTOR} .adunitwrapper.mv-native-size {
    margin-bottom: 0px;
  }

  .${AD_BOX_SELECTOR}[data-slotid="recipe_btf"] {
    float: right;
  }

  .${AD_BOX_SELECTOR} .mv-outstream-container {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${getCreateArrivalStyleHack(config.heightPxToContain, labelBuffer)}
`
// NOTE: we would not want to have these long term
const arrivalSlotBuffer = 55
function getCreateArrivalStyleHack(
  heightToContain: number,
  labelBuffer: number
) {
  return `.mv-pre-create-target[style*=height] {
    min-height: ${
      heightToContain + labelBuffer + arrivalSlotBuffer
    }px !important;
  }`
}

const adBoxTemplate = (
  content: string,
  slotId: string,
  heightStyle: string,
  widthStyle: string
): string =>
  `
<div class="${AD_BOX_SELECTOR}" data-slotid="${slotId}" style="height: ${heightStyle}; width: ${widthStyle};">
  ${content}
</div>
`

export const getAdBoxBySlot = (slot: Slot): HTMLElement | null =>
  document.querySelector(
    `.${AD_BOX_SELECTOR}[data-slotid="${slot.id}"]`
  ) as HTMLElement | null

export function adBoxIsEnabled(model: Pick<WebModel, 'ad_box'>): boolean {
  return !!model.ad_box
}

export function doWhenAdBoxIsEnabled(model: WebModel, thingToDo: () => any) {
  if (adBoxIsEnabled(model)) {
    return thingToDo()
  }
}
