import { Slot } from '../Slot'
import { IInterscrollerSlot } from './Interscroller'
import { IInviewSlot } from './InView'
/**
 * The rail layout class manages the interaction and styling of the rail
 * DOM which is used by both the inView and interscroller features. It
 * decides which styles should be applied based on the model settings and
 * ad that is rendered.
 */
export class RailLayout {
  constructor(readonly slot: Slot & IInterscrollerSlot & IInviewSlot) {}

  applyRailLayout() {
    // no op if decorators have not flagged applied rail DOM to slot
    if (!this.slot.rail.railStructureApplied) {
      return
    }

    // independent of which layout should be applied
    this.slot.rail.sizeRailStructureLogic()

    if (this.slot.interscrollerLayoutApplies) {
      this.activateInterscrollerLayout()
      return
    }

    if (this.slot.inViewIsEnabled) {
      this.activateInviewLayout()
      return
    }

    this.activateDefaultLayout()
  }

  private activateInterscrollerLayout() {
    this.removeInViewLayout()
    this.applyInterscrollerLayout()
  }

  private activateInviewLayout() {
    this.removeInterscrollerLayout()
    this.applyInviewLayout()
  }

  private activateDefaultLayout() {
    this.removeInterscrollerLayout()
    this.removeInViewLayout()
  }
  private applyInterscrollerLayout() {
    this.slot.interscrollerEnalbedForSlot &&
      this.slot.applyInterscrollerLayout.bind(this.slot)()
  }

  private removeInterscrollerLayout() {
    this.slot.interscrollerEnalbedForSlot &&
      this.slot.removeInterscrollerLayout.bind(this.slot)()
  }

  private applyInviewLayout() {
    this.slot.inViewIsEnabled && this.slot.applyInViewLayout.bind(this.slot)()
  }

  private removeInViewLayout() {
    this.slot.inViewIsEnabled && this.slot.removeInViewLayout.bind(this.slot)()
  }
}
