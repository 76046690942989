// Returns the content width of the element.
export default function getContentWidth(el: HTMLElement): number {
  const styles = window.getComputedStyle(el)
  const widthStyle = styles.width

  if (widthStyle.includes('%') && el.parentElement) {
    const percentModifier = parseFloat(styles.width)
    return percentModifier * getContentWidth(el.parentElement)
  }

  let contentWidth = parseFloat(styles.width || '0')
  if (styles.boxSizing === 'border-box') {
    contentWidth =
      contentWidth -
      parseFloat(styles.paddingRight || '0') -
      parseFloat(styles.paddingLeft || '0') -
      parseFloat(styles.borderRightWidth || '0') -
      parseFloat(styles.borderLeftWidth || '0')
  }

  return contentWidth
}
