/* eslint no-magic-numbers: 0 */
// https://www.iab.com/wp-content/uploads/2016/03/OpenRTB-API-Specification-Version-2-5-FINAL.pdf

export enum API {
  VPAID_1_0 = 1,
  VPAID_2_0 = 2,
  MRAID_1 = 3,
  ORMMA = 4,
  MRAID_2 = 5,
  MRAID_3 = 6
}

export enum Linearity {
  linear = 1,
  nonlinear = 2
}

export enum PlaybackMethod {
  autoplaySoundOn = 1,
  autoplaySoundOff = 2,
  ctp = 3,
  mouseover = 4,
  viewportSoundOn = 5,
  viewportSoundOff = 6
}

export enum Placement {
  stream = 1,
  banner = 2,
  article = 3,
  feed = 4,
  floating = 5
}

export enum DeliveryMethod {
  streaming = 1,
  progressive = 2,
  download = 3
}

export enum Protocol {
  VAST_1_0 = 1,
  VAST_2_0 = 2,
  VAST_3_0 = 3,
  VAST_1_0_wrapper = 4,
  VAST_2_0_wrapper = 5,
  VAST_3_0_wrapper = 6,
  VAST_4_0 = 7,
  VAST_4_0_wrapper = 8,
  DAAST_1_0 = 9,
  DAAST_1_0_wrapper = 10
}
