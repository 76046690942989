/**
 * Adds callbacks to an element's style changes.
 * Useful if you need to make changes right before and/or right
 * after an element recieves a style change.
 * @param el Element
 * @param hooks Pre and post style change callbacks
 */
export const addStyleHooks = (
  el: HTMLElement,
  {
    pre,
    post
  }: {
    pre?: (style?: string, oldValue?: any, newValue?: any) => any
    post?: (style?: string, oldValue?: any, newValue?: any) => any
  }
) => {
  Object.keys(el.style).forEach((k: string) => {
    if (Number.isInteger(parseInt(k, 10))) {
      return
    }
    try {
      Object.defineProperty(el.style, k, {
        set(newVal) {
          const oldVal = el.style.getPropertyValue(k)
          pre && pre(k, oldVal, newVal)
          newVal && el.style.setProperty(k, newVal)
          post && post(k, oldVal, newVal)
        }
      })
    } catch (e) {
      /* no-op */
    }
  })
}
