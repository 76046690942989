import { REFRESH_MINIMUM_VISIBILITY } from '../../constants'
import EventManager from '../../eventManager/EventManager'
import { doOnce } from '../../helpers/doOnce'
import { getIntersectionObserver } from '../../helpers/Observer'
import type { Slot } from '../Slot'
import SlotRepository from '../SlotRepository'

const belowRequiredViewability = 0.4999
const requiredViewability = 0.51

/**
 * Observer configed to notify us when we've fallen out of visibility and when
 * we're within visibility
 */
export const intersectionConfig: IntersectionObserverInit = {
  threshold: [belowRequiredViewability, requiredViewability]
}

/**
 * Responsible for tracking the visibility of a slot. A slot is
 * considered visible if 51%+ of it is in the viewport.
 * It fires a "viewed impression" once the ad has been viewed
 * for 1 second.
 */
export class VisibilityObserver {
  impressionViewed = false
  impressionTimeout

  constructor() {
    VisibilityObserver.addViewabilityObserver()
    VisibilityObserver.addRefreshObserver()
  }

  observe(el: HTMLElement): void {
    getIntersectionObserver().observe(el, intersectionConfig)
  }

  /** Resets the impression viewable state as a slot refreshes */
  static addRefreshObserver = doOnce(() =>
    EventManager.on(EventManager.events.slotRefreshed, resetImpressionViewable)
  )

  /** An Observer to trigger the Viewability related events. */
  static addViewabilityObserver = doOnce(() => {
    getIntersectionObserver().onChange((elements) => {
      elements.forEach(({ target, intersectionRatio }) => {
        const slot = SlotRepository.getSlotByWrapper(target)
        if (!slot) {
          return
        }

        triggerSlotVisibilityChange(slot, intersectionRatio)
        triggerImpressionViewable(slot, intersectionRatio)
      })
    }, intersectionConfig)
  })
}

/** Dispatches the change of the slots visibility */
const triggerSlotVisibilityChange = (slot: Slot, intersectionRatio: number) => {
  slot.visible = intersectionRatio >= REFRESH_MINIMUM_VISIBILITY

  EventManager.trigger(
    EventManager.events.slotVisibilityChanged,
    intersectionRatio,
    slot
  )
}

/** Sets a 1 sec timer to trigger impression viewable  */
const triggerImpressionViewable = (slot: Slot, intersectionRatio: number) => {
  const { visibilityObserver } = slot
  const impressionNotViewed = !visibilityObserver.impressionViewed
  if (impressionNotViewed && isInView(intersectionRatio)) {
    slot.visibilityObserver.impressionTimeout =
      slot.visibilityObserver.impressionTimeout ||
      createViewabilityTimeout(slot)

    /**
     * Not in view, but we've got an active impression timeout
     * so we clear it.
     */
  } else if (visibilityObserver.impressionTimeout) {
    clearTimeout(visibilityObserver.impressionTimeout)
    visibilityObserver.impressionTimeout = null
  }
}

const createViewabilityTimeout = (slot: Slot) => {
  const { visibilityObserver } = slot
  return setTimeout(() => {
    visibilityObserver.impressionViewed = true
    EventManager.trigger(EventManager.events.impressionViewable, slot)
  }, 1000)
}

const isInView = (intersectionRatio) => intersectionRatio > 0.5

const resetImpressionViewable = (slot: Slot) =>
  (slot.visibilityObserver.impressionViewed = false)
