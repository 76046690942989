import type { Slot } from '../Slot'
import { getRandom } from '../../helpers/array'
import { randomBool } from '../../helpers/random'
import { Device } from '../../helpers/Device'

/**
 * Composition class for the @Slot class
 * A Slot will tell the Timeout class what its default timeout is at
 * instantiation. Timeout will decide whether to use it, or to pick a
 * random value out of the timeouts array above.
 */
export class Timeout implements ITimeout {
  private timeoutMap: ITimeoutMap
  private testActive = false

  constructor(
    readonly slot: Slot,
    slotTimeouts: RecursivePartial<ITimeoutMap> = { desktop: {}, mobile: {} }
  ) {
    this.timeoutMap = {
      desktop: {
        ...defaultTimeoutMap.desktop,
        ...slotTimeouts.desktop
      },
      mobile: {
        ...defaultTimeoutMap.mobile,
        ...slotTimeouts.mobile
      }
    }

    if (randomBool(trafficPercentage)) {
      this.testActive = true
      const randomTimeout = getRandom(timeouts)

      this.timeoutMap = {
        mobile: {
          atf: randomTimeout,
          lazy: randomTimeout,
          ref: randomTimeout
        },
        desktop: {
          atf: randomTimeout,
          lazy: randomTimeout,
          ref: randomTimeout
        }
      }
    }
  }

  get targetingCode() {
    const auctionType = getAuctionType(this.slot)
    const device = Device.isMobileOrTablet ? 'mobile' : 'desktop'
    return `${this.slot.slotName}_${auctionType}_${device[0]}_${this.value}`
  }

  get value(): TimeoutValue {
    const auctionType = getAuctionType(this.slot)
    const device = Device.isMobileOrTablet ? 'mobile' : 'desktop'
    const value = this.timeoutMap[device][auctionType] as TimeoutValue

    return this.testActive ? value : ((value + 1) as TimeoutValue)
  }
}

export type ITimeoutMap = {
  mobile: { [key in AuctionType]: number }
  desktop: { [key in AuctionType]: number }
}

/**
 * Ha this is clown shoes, but it makes an array of 26 values
 * from 500 to 3000 in increments of 100.
 */
// eslint-disable-next-line no-magic-numbers
export const timeouts = Array.from({ length: 26 }, (_, k) => (k - 5 + 10) * 100)
export const trafficPercentage = 0.1

/**
 * Interface of the Timeout class. Typing implementors to use this will
 * make mocks easier during testing.
 */
export interface ITimeout {
  readonly value: TimeoutValue
  readonly targetingCode: string
}

/* eslint-disable no-magic-numbers */
export const timeout = () => {
  if (Device.isMobile) {
    return 1800
  }
  if (Device.isTablet) {
    return 2100
  }

  return 1800 // desktop
}

export const lazyTimeout = () => {
  if (Device.isMobile) {
    return 1100
  }
  if (Device.isTablet) {
    return 1400
  }

  return 1200 // desktop
}

export const refreshTimeout = () => {
  if (Device.isMobile) {
    return 1700
  }
  if (Device.isTablet) {
    return 1500
  }

  return 1200 // desktop
}

/** Timeout default settings */
export const defaultTimeoutMap = {
  mobile: {
    atf: timeout(),
    lazy: lazyTimeout(),
    ref: timeout()
  },
  desktop: {
    atf: timeout(),
    lazy: lazyTimeout(),
    ref: timeout()
  }
}

/**
 * This is just a flagging pattern to make sure we use not just any number.
 * We'll only be able to get a TimeoutValue from a Timeout class. That way,
 * we can type the timeout value consumer to only accept TimeoutValue types
 * and we will be forced to only source timeouts from the Timeout Class,
 * since its the only place you can get a TimeoutValue.
 */

export type TimeoutValue = number & { flag: true }

enum AuctionType {
  refresh = 'ref',
  lazy = 'lazy',
  atf = 'atf'
}

const getAuctionType = (slot: Slot): AuctionType => {
  if (slot.isRefreshing) {
    return AuctionType.refresh
  }
  return slot.lazy ? AuctionType.lazy : AuctionType.atf
}
