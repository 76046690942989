import { isTruthyAttribute } from '../helpers/DOM/isTruthyAttribute'
export const stickySlotAttr = 'data-sticky-slot'
export const stickyOffsetAttr = 'data-sticky-slot-offset'
export const stickyStopSelectorAttr = 'data-sticky-slot-stop'

export class StickyConfig {
  isSticky: boolean
  offset: number | undefined
  stopSelector: string | undefined

  constructor(slotModel: ISlotModel, target: HTMLElement) {
    this.isSticky = isStickySlot(slotModel, target)
    this.offset = getStickyOffset(slotModel, target)
    this.stopSelector = getStickyStopSelector(slotModel, target)
  }
}

export const isStickySlot = (
  { sticky }: Pick<ISlotModel, 'sticky'>,
  target: HTMLElement
): boolean => {
  return (
    sticky ||
    (!!target && isTruthyAttribute(target.getAttribute(`${stickySlotAttr}`)))
  )
}

export const getStickyOffset = (
  { stickyOffset }: Pick<ISlotModel, 'stickyOffset'>,
  target: HTMLElement
): number | undefined => {
  if (target && target.hasAttribute(stickyOffsetAttr)) {
    return parseInt(target.getAttribute(stickyOffsetAttr) || '', 10)
  }

  return stickyOffset
}

export const getStickyStopSelector = (
  { stickyStopSelector }: Pick<ISlotModel, 'stickyStopSelector'>,
  target: HTMLElement
): string | undefined => {
  if (target && target.hasAttribute(stickyStopSelectorAttr)) {
    return target.getAttribute(stickyStopSelectorAttr) || ''
  }

  return stickyStopSelector
}
