import {
  IAdServerTargeting,
  IBidResponse,
  IPrebidAdUnit,
  IBidRequest
} from '../../typings/IPrebid'
import { deepcopy } from '../helpers/deepcopy'
import { spread } from '../helpers/spread'
import type WebModel from '../models/WebModel'
import type * as Slots from './'
import SlotCounter from './SlotCounter'
import { Device } from '../helpers'
import { SLOT_RENDER_ATTR, DFP_ID } from '../constants'
import { addClass, addAdDimensionsClass, removeClass } from '../helpers/DOM'
import type Lazyable from '../lazyable/Lazyable'
import { AdType, IAdType } from '../enums/AdType'
import { Timeout, ITimeout } from './helpers/Timeout'
import { getMutationObserver } from '../helpers/Observer'
import SlotRepository from './SlotRepository'
import { VisibilityObserver } from './helpers/VisibilityObserver'
import SizeLiftTest from '../sizeLiftTest/SizeLiftTest'
import { StickyConfig } from '../stickySlot/StickyConfig'
import {
  isBelowFloor,
  isBelowOutstreamFloor
} from '../googletagFacade/GoogletagFacade'
import { calculateUrTargeting } from '../googletagFacade/helpers/targetingCalculators'
import { Flooring } from '../flooring/Flooring'
import Logger from '../helpers/Logger'
import { addStyleHooks } from '../helpers/addStyleHooks'
import { Placement } from '../enums/OpenRTB'
import { AdBoxConfig, doWhenAdBoxIsEnabled } from './helpers/AdBox'
import type { IIBVConfig } from '../ibv/IBVConfig'
import type { AdPlayer } from '../adPlayer/AdPlayer'
import getContentWidth from '../helpers/DOM/getContentWidth'
import { IInterscrollerConfig } from './helpers/Interscroller'
import { RailStructure } from './helpers/RailStructure'
import { RailLayout } from './helpers/RailLayout'
import { AdServerTargetKey } from '../enums/AdServerTargetKey'

export enum AdUnitPathMode {
  mcm = 'mcm',
  spm = 'spm'
}

// Note: This sizes below are ordered by their revenue, descending (except 1x1)
// https://github.com/mediavine/web-wrapper/issues/774
export const validPrebidSizes: Array<AdSize> = [
  [300, 250],
  [728, 90],
  [300, 600],
  [160, 600],
  [320, 50],
  [300, 50],
  [320, 100],
  [250, 250],
  [468, 60],
  [336, 280],
  [970, 250],
  [970, 90],
  [300, 1050],
  [300, 200],
  [320, 480],
  // gg in article
  // eslint-disable-next-line no-magic-numbers
  [300, 400]
]

export const mutationConfig = { subtree: true, childList: true }
const belowRequiredViewability = 0.49
const requiredViewability = 0.5
const intersectionConfig: IntersectionObserverInit = {
  threshold: [belowRequiredViewability, requiredViewability]
}
const maxOutstreamWidth = 510

const DEFAULT_PREAUCTION_SECONDS = 3

export abstract class Slot {
  private _wrapper: HTMLElement
  private _railStructure: RailStructure
  private _railLayout: RailLayout
  _targeting: ISlotTargeting = {}
  protected _gSlot?: googletag.Slot | null
  protected _sizes: AdSizes
  number: number
  adUnitId: string
  reauctions = 0
  refreshes = 0
  type: SlotClass
  lazy: boolean
  lazyable: Lazyable
  selector: string | HTMLElement
  slotName: string
  insertPosition: InsertPosition
  refreshRetries: number = 0
  refreshTime?: number
  preAuctionTime: number = DEFAULT_PREAUCTION_SECONDS
  restrictRefreshSize: boolean = false
  refreshSizeRestricted: boolean = false
  templateHideMS = 100

  /** used for Interstitial ad slots.  Indicates the ad doesn't have a div on the page. */
  outOfPage: boolean = false
  dynamic: boolean
  timeout: ITimeout
  highestPrebid?: IBidResponse | null
  target: HTMLElement | null
  /** Height of ad within slot */
  height: number
  fixedHeight: number
  /** Width of ad within slot */
  width: number
  adType: IAdType
  visible: boolean = false
  visibilityObserver = new VisibilityObserver()
  stickyConfig: StickyConfig
  /** Did this slot get created because of a Hint? */
  fromHint: boolean = false

  /** Default value for Outstream ORTB video placement */
  outstreamPlacement = Placement.article
  maxOutstreamWidth = maxOutstreamWidth

  /** Is this slot using a rail? */
  onRail: boolean = false

  /**
   * Determines if this slot is eligible for AdX (i.e.: google) to bid on it.
   * If false, there won't be a separate auction in Google after we send the winning header bid to GAM.
   */
  adXEligible: boolean = true
  /**
   * Determines if this slot is eligible to contain PSA (Public Service Announcement) filler ads.
   */
  psaEligible: boolean = true
  /**
   * Enables Ad Box
   */
  adBoxConfig?: AdBoxConfig

  /**
   * Enables Interscroller
   */
  interscrollerConfig?: IInterscrollerConfig

  /**
   * The HTML element that contains the AdReporter and Mediavine Branding
   * By default it is placed adjacent to the Ad Wrapper, but appended to adBox
   * if the Slot has the `hasAdBox` decorator.
   */
  get adReporterAnchor(): HTMLElement {
    return this.wrapper
  }

  ibvConfig?: IIBVConfig

  constructor(
    readonly slotModel: ISlotModel,
    readonly model: WebModel,
    protected _document: Document = document
  ) {
    spread(slotModel).onto(this)
    this.number = SlotCounter.nextAvailable(slotModel.type)
    this.target = getTarget(slotModel)
    this.stickyConfig = new StickyConfig(slotModel, this.target)
    this.timeout = new Timeout(this)
    /**
     * This watches for subtree changes and resizes our containers if one occurs.
     * Doing this prevents race conditions between our resizing logic and when GPT
     * sizes their slot's iframe during a refresh.
     */
    Slot.initMutationObserver()
  }

  total(): number {
    return SlotCounter.total(this.slotModel.type)
  }

  /** pixels from bottom of screen where lazy loading add will render */
  get offset(): number | undefined {
    return undefined
  }

  get sizes(): AdSizes {
    return this.sizesThatFitInParent(this._sizes)
  }

  set sizes(updatedAdSizes: AdSizes) {
    this._sizes = updatedAdSizes
  }

  /**
   * Inspects our slot's parent's width and ensure our potential ad sizes
   * can fit in the parent.
   */
  private sizesThatFitInParent(sizes: AdSizes): AdSizes {
    const minAdSize = 320
    const element =
      typeof this.selector === 'string'
        ? document.querySelector(this.selector)
        : (this.selector as HTMLElement)
    const parent = element && (element.parentNode as HTMLElement | null)
    const parentWidth = (parent && getContentWidth(parent)) || Infinity
    return sizes.filter(
      (size) =>
        !Array.isArray(size) || size[0] <= minAdSize || size[0] <= parentWidth
    )
  }

  validate(): boolean {
    return !this.hasRendered()
  }

  hasRendered(): boolean {
    return (
      !!this.target &&
      this.target.hasAttribute(`${SLOT_RENDER_ATTR}-${this.slotModel.adUnitId}`)
    )
  }

  get bidRequests(): Array<IBidRequest> {
    const unit = this.model.bidRequests[this.adUnitId] || []
    return deepcopy(unit)
  }

  get id(): string {
    if (this.number === 1) {
      return this.adUnitId
    } else {
      return this.adUnitId.replace('_', `_${this.number}_`)
    }
  }

  /**
   * Previously treated as an optional abstract.
   * Treat this method as final unless you can not
   * get your desired result from overriding the adUnit
   * method.
   */
  async adunits(): Promise<IPrebidAdUnit[]> {
    return [
      {
        code: this.id,
        bids: this.bidRequests,
        mediaTypes: {
          banner: {
            sizes: Slot.getValidPrebidSizes(this.sizes)
          }
        }
      }
    ]
  }

  get gSlot(): googletag.Slot | null {
    // We intentionally will not try to register the slot if this._gSlot has already been set to null.
    if (this._gSlot === undefined) {
      this._gSlot = Slot.registerSlot(this)

      if (this.model.ad_box) {
        googletag.pubads().addEventListener('slotOnload', (e) => {
          const slot = e.slot
          if (slot === this._gSlot) {
            this.attachClsHack()
          }
        })
      }
    }

    return this._gSlot
  }

  /**
   * This function gets called in the event that retry logic has given up requesting
   * more bids because of consecutive non-responses (above MAX_REFRESH_RETRIES)  from the bidders.
   */
  onRefreshRetryFail() {
    // Implement behavior in subclasses (e.g. UniversalPlayer)
  }

  attachClsHack() {
    const iframe = this.iframe
    if (iframe && !iframe.getAttribute('data-hooks')) {
      this.applyCLSHack(iframe)
      iframe.setAttribute('data-hooks', 'true')

      return true
    }
  }

  blockRenderForCLS(): boolean {
    return false
  }

  /** This function will be called by the OutstreamRenderer when it's ready for PlayerEvent events to be registered. */
  registerOutstreamEvents(player: AdPlayer) {
    // Implement behavior in subclasses (e.g. UniversalPlayer)
  }

  get wrapperId(): string {
    // Use while A/B testing 1.0 to 2.0
    return `${this.id}_wrapper`

    // Use after 2.0 launch
    // return `${this.adUnitId}_wrapper`
  }

  get wrapper(): HTMLElement {
    if (this._wrapper && this._wrapper.id === this.wrapperId) {
      /**
       * Double applying observers does not harm
       * anyting so why not double check its set?
       */
      getMutationObserver().observe(this._wrapper, mutationConfig)
      this.visibilityObserver.observe(this._wrapper)

      return this._wrapper
    }

    const wrapper = document.querySelector(
      `[data-wrapper="${this.id}"]`
    ) as HTMLElement

    if (wrapper) {
      /** Setup observers waiting on wrapper to be available */
      getMutationObserver().observe(wrapper, mutationConfig)
      this.visibilityObserver.observe(wrapper)
    }

    this._wrapper = wrapper
    return this._wrapper
  }

  get rail(): RailStructure {
    if (!this._railStructure) {
      this._railStructure = new RailStructure(this)
    }

    return this._railStructure
  }

  get railLayout(): RailLayout {
    if (!this._railLayout) {
      this._railLayout = new RailLayout(this as any)
    }

    return this._railLayout
  }

  get isRefreshing(): boolean {
    return !!this.refreshTime && this.refreshTime > 0 && this.refreshes >= 1
  }

  get adUnitPath() {
    const adUnitMode = this.getAdUnitPathMode()

    if (adUnitMode === AdUnitPathMode.mcm) {
      return `/${DFP_ID},${this.model.mcmNetworkCode}/${this.model.adunit}/${
        this.slotName || 'other'
      }`
    } else {
      return `/${DFP_ID}/${this.model.adunit}/${this.slotName || 'other'}`
    }
  }

  /**
    1.) If they don’t have SPM approval and they dont’ have MCM approval, we should not run google
    2.) If they have only one of MCM approval or SPM approval, we should use whichever they are approved for
    3.) If they have both MCM approval and SPM approval, we should look to mcm_tagging to tell us which method to tag for
      a.) If mcm_tagging is true, use MCM tagging
      b.) if mcm_tagging is false, use SPM tagging
   */
  getAdUnitPathMode(): AdUnitPathMode {
    const model = this.model
    let adUnitMode: AdUnitPathMode

    if (model.mcmStatusApproved && model.spm_approval) {
      adUnitMode = model.mcm_tagging ? AdUnitPathMode.mcm : AdUnitPathMode.spm
    } else if (model.mcmStatusApproved) {
      adUnitMode = AdUnitPathMode.mcm
    } else if (model.spm_approval) {
      adUnitMode = AdUnitPathMode.spm
    } else {
      // :( fall back to spm
      adUnitMode = AdUnitPathMode.spm
    }

    return adUnitMode
  }

  static registerSlot(slot: Slot): googletag.Slot | null {
    const sizeLiftTest = new SizeLiftTest(slot)

    slot.sizes = sizeLiftTest.slotSizes

    const adUnitPath = slot.adUnitPath

    let gSlot: googletag.Slot | null
    if (slot.outOfPage) {
      // If interstitials are not valid for this page, defineOutOfPageSlot() will return null.
      // The @types/doubleclick-gpt typing for this function is incorrect (as of 01/05/2021).
      gSlot = window.googletag.defineOutOfPageSlot(
        adUnitPath,
        googletag.enums.OutOfPageFormat.INTERSTITIAL as unknown as string
      ) as googletag.Slot | null
    } else {
      gSlot = window.googletag.defineSlot(adUnitPath, slot.sizes, slot.id)
    }

    if (gSlot) {
      gSlot.addService(window.googletag.pubads())
      window.googletag.display(gSlot)
    }

    return gSlot
  }

  destroyGSlot() {
    if (this._gSlot) {
      window.googletag.destroySlots([this._gSlot])
      delete this._gSlot
    }
  }

  // Safe to override
  template(): string {
    return `
      <div id='${this.wrapperId}' class='adunitwrapper ${this.adUnitId}_wrapper mv-empty-wrapper' data-wrapper='${this.id}' data-nosnippet>
        <div id='${this.id}' class='adunit'>
        </div>
      </div>
    `
  }

  setTargeting(targetingValues: Partial<ISlotTargeting>): Slot {
    this._targeting = {
      ...this._targeting,
      ...targetingValues
    }

    return this
  }

  applyTargeting(): Slot {
    const gSlot = this.gSlot
    if (!gSlot) {
      Logger.debug(`gSlot is undefined on ${this.id}`)
    } else {
      gSlot.clearTargeting()
      this.filterTargeting()
      Object.keys(this._targeting).forEach((key) => {
        const value = this._targeting[key]
        if (value || value === 0) {
          gSlot.setTargeting(key, value)
        }
      })
    }

    // Clear targeting so weird stuff doesn't happen with refreshables
    this._targeting = {}

    return this
  }

  private filterTargeting(): void {
    // set UR if no bids
    if (!this._targeting.hb_pb) {
      this._targeting.UR = calculateUrTargeting(
        Flooring.getDisplayFloor(this.model, this.adUnitId)
      )
    }

    // highest bid is below the floor.
    if (
      isBelowFloor(
        this._targeting,
        Flooring.getDisplayFloor(this.model, this.adUnitId)
      ) ||
      isBelowOutstreamFloor(
        this._targeting,
        Flooring.getOutstreamFloor(this.model)
      )
    ) {
      this._targeting.hb_adid = undefined
      this._targeting.hb_bidder = undefined
      this._targeting.hb_pb = undefined
      this._targeting.UR = calculateUrTargeting(
        Flooring.getDisplayFloor(this.model, this.adUnitId)
      )
    }

    /* Ceiling velocity to 1000 */
    if (this._targeting.velocity && this._targeting.velocity > 1000) {
      this._targeting.velocity = 1000
    }
  }

  static getValidPrebidSizes(sizes: AdSizes): AdSizes {
    return sizes.filter((size) => {
      const isValidSize = validPrebidSizes.find(
        (validSize) => size[0] === validSize[0] && size[1] === validSize[1]
      )

      return size !== 'fluid' && isValidSize
    })
  }

  get iframe(): HTMLIFrameElement | null {
    const { wrapper } = this
    if (!wrapper) {
      return null
    }
    return (
      (wrapper.querySelector('.adunit iframe') as HTMLIFrameElement) || null
    )
  }

  /**
   * Determines if this slot is valid to place an outstream ad into.
   */
  meetsOutstreamCriteria(): boolean {
    // Override in child class to implement
    return false
  }

  applyCLSHack(iframe: HTMLIFrameElement) {
    addStyleHooks(iframe, {
      pre: this.conditionalHideTemplate,
      post: this.conditionalShowTemplate
    })
  }

  /**
   * Ad Box-related feature to hide/show wrapper
   * Will only work when ad_box is enabled!!
   */

  conditionalHideTemplate = () => {
    doWhenAdBoxIsEnabled(this.model, this.hideTemplate)
  }

  hideTemplate = () => {
    this.getTemplateTopElements().forEach((element) => {
      element.style.display = 'none'
    })
  }

  /**
   * Ad Box-related feature to hide/show wrapper
   * Will only work when ad_box is enabled!!
   */
  conditionalShowTemplate = () => {
    doWhenAdBoxIsEnabled(this.model, () =>
      setTimeout(() => this.showTemplate(), this.templateHideMS)
    )
  }

  showTemplate = () => {
    this.getTemplateTopElements().forEach((element) => {
      element.style.display = ''
    })
  }

  getTemplateTopElements(): HTMLElement[] {
    return [this.wrapper]
  }

  getSlotDom(): HTMLElement {
    return this.wrapper
  }

  sizeContainers(): void {
    this.conditionalHideTemplate()
    this.sizeContainersLogic()
    this.conditionalShowTemplate()
  }

  sizeContainersLogic(): void {
    const { height, fixedHeight, width, adType, wrapper, iframe } = this
    if (this.type === 'Interstitial') {
      // Interstitials are different.  they don't have a wrapper div to manipulate,
      // and we don't have access to the correct iframe in this context.
      // It's handled in the renderAd.ts file.
      return
    }
    this.resetSizeClasses()
    this.railLayout.applyRailLayout()

    addAdDimensionsClass({ size: [width, fixedHeight || height] }, wrapper)
    this.displayAdUnitLabels(adType)
    switch (adType) {
      case AdType.prebidStandard:
        this.sizeWrapper()
        this.sizeIframe()
        addClass(wrapper, 'mv-dynamic-size')
        break

      case AdType.adXStandard:
        this.sizeWrapper()
        break

      case AdType.inBannerNative:
        wrapper.style.height = `initial`
        wrapper.style.minHeight = `initial`
        wrapper.style.width = `initial`
        addClass(wrapper, 'mv-native-size')
        break

      case AdType.adXNative:
        this.sizeAdxNativeWrapper()
        addClass(this.wrapper, 'native')
        break

      case AdType.prebidNative:
        wrapper.style.height = `initial`
        wrapper.style.width = `initial`
        if (iframe) {
          iframe.style.height = '0px'
          iframe.style.width = '0px'
        }
        break
    }
  }

  resetSizeClasses({ wrapper } = this) {
    removeClass(wrapper, 'mv-dynamic-size')
    removeClass(wrapper, 'native')
    removeClass(wrapper, 'mv-empty-wrapper')
    removeClass(wrapper, 'mv-native-size')
    removeClass(document.body, 'mv-adhesion-native')
  }

  sizeIframe({ iframe, height, width } = this) {
    if (!iframe) {
      return
    }
    iframe.style.height = `${height}px`
    iframe.style.width = `${width}px`
  }

  sizeWrapper({ wrapper, height, fixedHeight, width } = this) {
    wrapper.style.height = `${fixedHeight || height}px`
    wrapper.style.minHeight = `${fixedHeight || height}px`
    wrapper.style.width = `${width}px`
    if (fixedHeight && fixedHeight > height) {
      // wrapper.style.display = 'flex'
      wrapper.style.flexDirection = 'column'
      wrapper.style.justifyContent = 'center'
    } else {
      // wrapper.style.display = 'block'
    }
  }

  /**
   * For Adx Native ads, a Mutation Observer is used to listen for
   * changes to the height attribute within the wrapper's iframe.
   * This allows the parent wrapper to receive a 'min-height'
   * attribute that is based upon the size of the child iframe. This is
   * done to prevent "content skipping/shifting" issues when Lazyable
   * ad cleanup occurs, resolving the issue found in
   * https://github.com/mediavine/web-wrapper/issues/924
   */
  sizeAdxNativeWrapper({ wrapper } = this) {
    const iframe = wrapper.querySelector('iframe')

    if (iframe) {
      const mutationCallback = (
        mutationsList: Array<MutationRecord>,
        observer: MutationObserver
      ) => {
        mutationsList.forEach(({ attributeName }) => {
          if (attributeName === 'height') {
            wrapper.style.minHeight = `${iframe.offsetHeight}px`
            observer.disconnect()
          }
        })
      }
      const observer = new MutationObserver(mutationCallback)
      observer.observe(iframe, { attributes: true })
    }
  }

  displayAdUnitLabels(adType: IAdType, { adReporterAnchor, model } = this) {
    const labels = adReporterAnchor.querySelector(
      'mv-ad-reporter'
    ) as HTMLElement
    if (!labels) {
      return
    }

    labels.style.display = 'block'
  }

  cleanup(): void {
    this.wrapper.style.display = 'none'
    getMutationObserver().disconnect(this.wrapper, mutationConfig)
  }

  private static mutationObserverInitialized = false
  private static initMutationObserver() {
    if (Slot.mutationObserverInitialized) {
      return
    }

    getMutationObserver().onChange((mutatedElements) => {
      mutatedElements.forEach((element) => {
        const target = element.target as HTMLElement
        const slotId = target.getAttribute('data-wrapper') || ''
        const slot = SlotRepository.getSlotById(slotId)
        slot && slot.sizeContainers()
      })
    }, mutationConfig)

    Slot.mutationObserverInitialized = true
  }
}

declare global {
  type SlotClass = keyof typeof Slots
}

export interface ISlotTargeting extends Partial<IAdServerTargeting> {
  google?: '0' | '1'
  native?: '0' | '1'
  /** origional encoded amazon bid */
  amznbid?: string
  /** unshielded bid (discreps applied) */
  amznbidub?: string
  /** key that lights up amazon line item */
  amznadj?: string
  amzniid?: string
  amznp?: string
  amznsize?: string
  arrival?: '1' | '0'
  bidFloor?: string
  dynFloor?: string
  [AdServerTargetKey.hb_bid]?: string
  [AdServerTargetKey.hb_bidder]?: string
  [AdServerTargetKey.hb_count]?: string
  [AdServerTargetKey.hb_pmp]?: '1' | '0'
  [AdServerTargetKey.hb_pool]?: '1' | '0'
  ad_size?: string
  inview?: '1' | '0'
  lazy?: '1'
  maxVelocity?: number
  OE?: '1' | '0'
  offsetMultiplier?: number
  pid?: string
  player_group?: string
  psa?: Array<string>
  pp?: Array<string>
  refresh_count?: number
  refresh?: string
  slot_number?: string
  slot?: string
  slot_id?: string
  timeout?: string
  UR?: string | string[]
  URP?: string
  velocity?: number
  sizeLift?: string
  [AdServerTargetKey.hb_pb_amazon]?: string | number
  [AdServerTargetKey.hb_pb_appnexus]?: string | number
  [AdServerTargetKey.hb_pb_appnexusAst]?: string | number
  [AdServerTargetKey.hb_pb_indexExchange]?: string | number
  [AdServerTargetKey.hb_pb_rubicon]?: string | number
  [AdServerTargetKey.hb_pb_triplelift]?: string | number
  [AdServerTargetKey.hb_liv]?: string
  [AdServerTargetKey.hb_safeframe]?: '1' | '0'
  ccpa?: string
  partnerLift?: Array<string>
  gid?: '0' | '1' | '2'
}

function getTarget({ selector, mobileSelector }: ISlotModel): HTMLElement {
  if (selector instanceof Element) {
    return selector as HTMLElement
  } else {
    return Device.isMobileOrTablet && mobileSelector
      ? (document.querySelector(mobileSelector) as HTMLElement)
      : (document.querySelector(selector) as HTMLElement)
  }
}
