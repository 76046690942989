import { Device } from '../Device'

export function isBelowViewport({
  selector,
  insertPosition,
  mobileInsertPosition
}: ISlotModel): boolean {
  const viewportHeight = window.innerHeight
  const element =
    selector instanceof Element
      ? selector
      : (document.querySelector(selector) as Element)

  if (!element) {
    return false
  }

  const { top, height } = element.getBoundingClientRect()
  const position = Device.isMobileOrTablet
    ? mobileInsertPosition
    : insertPosition

  const totalDistanceFromViewport =
    position === 'beforebegin' || position === 'afterbegin' ? top : top + height

  return totalDistanceFromViewport > viewportHeight
}

export function checkElementInsertedBelowViewport(
  element: HTMLElement,
  insertPosition: InsertPosition,
  insertHeight: number
): boolean {
  const clientHeight = document.documentElement.clientHeight
  const rect = element.getBoundingClientRect()
  switch (insertPosition) {
    case 'beforebegin':
    case 'afterbegin':
      // element starts entirely off screen so inserting there or after is safe
      return rect.top > clientHeight
    case 'beforeend':
      // bottom is far enough off screen to fit the height of the inserted element
      return rect.bottom - insertHeight > clientHeight
    case 'afterend':
    default:
      // bottom is already off screen so inserting after is safe
      return rect.bottom > clientHeight
  }
}
