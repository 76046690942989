import type { Slot } from '../slots/Slot'
import Logger from '../helpers/Logger'

export const TEST_PERCENT = 0.02 // 2 percent

// tests do not run on these sizes
const sizeBlocklist: AdSizes = [
  [300, 250],
  [320, 50],
  [728, 90]
]

/**
 * This module performs a test to determine the lift of each slot size. There
 * is a 2% chance that a test will occur. Of the tests performed, there is a
 * 50/50 chance that a run will be an actual test or control. If a run is a
 * test, a size is picked at random to remove from the SizeLiftTest._slotSizes
 *  array. Using the Slot.adUnitId value, a new string is then created with
 * information about what size was removed and whether it was a test or
 * control run (e.g., "sidebar_atf" would change to "sidebar_atf_300x1050_t"
 * with "_t" representing that it was a test). If a control is performed, all
 * the above still occurs, but no value is removed from the
 * SizeLiftTest._slotSizes array. A control is denoted by the appended "_c"
 * value to the new string that is created. Whether a run is a test or
 * control, this new string is then passed to Googletag's setTargeting method
 * as the value for the key "sizeLift". The slotSizes method returns the
 * this._slotSizes array with any removed sizes (if applicable).
 */
export default class SizeLiftTest {
  private _slotSizes: AdSizes

  constructor(slot: Slot) {
    this._slotSizes = slot.sizes

    let type: 't' | 'c'
    let randomSize: AdSize | string

    const format = (a: AdSize | string) => (Array.isArray(a) ? a.join('x') : a)

    const randomIndex = Math.floor(Math.random() * this._slotSizes.length)

    const hasOneSize = this._slotSizes.length === 1
    const blocklisted = sizeBlocklist.find(
      (item) => format(item) === format(this._slotSizes[randomIndex])
    )

    if (hasOneSize || blocklisted || Math.random() > TEST_PERCENT) {
      return
    }

    // 50/50 chance for a test or control run
    // eslint-disable-next-line no-magic-numbers
    if (Math.random() <= 0.5) {
      type = 't'
      randomSize = this._slotSizes.splice(randomIndex, 1)[0]
      Logger.debug(
        `SizeLiftTest: slot ${slot.adUnitId} size ${format(randomSize)} REMOVED`
      )
    } else {
      type = 'c'
      randomSize = this._slotSizes[randomIndex]
    }

    // set the slot's targeting for this test run, passing the key/value
    slot.setTargeting({
      sizeLift: `${slot.adUnitId}_${format(randomSize)}_${type}`
    })
  }

  public get slotSizes(): AdSizes {
    return this._slotSizes
  }
}
