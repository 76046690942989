import EventManager from '../../eventManager/EventManager'
import { Device } from '../../helpers'
import { writeCss } from '../../helpers/DOM'
import { doOnce } from '../../helpers/doOnce'
import WebModel from '../../models/WebModel'
import type { Slot } from '../Slot'
import { AdBoxConfig } from './AdBox'

export const railFrameSelector = 'mv-rail-frame'
export const railSlideSelector = 'mv-rail-slide'
export const railStickySelector = 'mv-rail-sticky'

export const railFrameDefaultHeight = 400
const maxAdWidth = 320
const topBuffer = 0
const adReportVerticalSpace = 20 //px

/**
 * The rail structure class deals with the rail DOM, its setup, and
 * sizing, independent of any feature. It doesn't rely on or use any
 * other classes.
 */
export class RailStructure {
  railStructureApplied: boolean = false

  constructor(readonly slot: Slot) {}

  public setupWrapElementListener() {
    // after wrapper has rendered, wrap the slot in DOM that makes it rail
    EventManager.on(EventManager.events.slotWrapperRenderEnded, (eventSlot) => {
      if (eventSlot !== this.slot) {
        return
      }
      this.wrapElementOnce(this.slot.wrapper || document.createElement('div'))
    })
  }

  public sizeRailStructureLogic() {
    const { height, width } = this.slot
    if (height <= 1 || width <= 1) {
      this.sticky.style.width = 'fit-content'
      this.sticky.style.height = 'fit-content'
    } else {
      this.sticky.style.width = `${width}px`
      this.sticky.style.height = `${height}px`
    }
  }

  public spaceNeededForAdReport(): number {
    // This makes sure that wide ads don't overlap with the ad-reporter-button
    // If an ad would overlap, we add padding-bottom to the slide to move the
    // button out of the way.
    const { height, width } = this.slot
    const parentWidth = this.slot.target?.offsetWidth
    const widthNeededForNoBottomPadding = 44 //px
    if (
      height > 1 &&
      width > 1 &&
      parentWidth &&
      parentWidth - widthNeededForNoBottomPadding <= width
    ) {
      return adReportVerticalSpace
    }

    return 0
  }

  private _frame?: HTMLElement
  get frame() {
    this._frame =
      this._frame ||
      createElement('div', {
        class: railFrameSelector,
        'data-slotid': this.slot.id
      })

    return this._frame
  }

  private _slide?: HTMLElement
  get slide() {
    this._slide =
      this._slide ||
      createElement('div', {
        class: railSlideSelector,
        'data-slotid': this.slot.id
      })

    return this._slide
  }

  private _sticky?: HTMLElement
  get sticky() {
    this._sticky =
      this._sticky ||
      createElement('div', {
        class: railStickySelector,
        'data-slotid': this.slot.id
      })

    return this._sticky
  }

  private createRailFrame() {
    const railFrame = this.frame
    const railSlide = this.slide
    const railSticky = this.sticky
    railSlide.appendChild(railSticky)
    railFrame.appendChild(railSlide)

    return railFrame
  }

  wrapElementOnce = doOnce(this.wrapElement.bind(this))

  private wrapElement(el: HTMLElement) {
    if (!el.parentElement) {
      return
    }
    const railFrame = this.createRailFrame()
    el.parentElement.insertBefore(railFrame, el)
    if (!railFrame.firstChild || !railFrame.firstChild.firstChild) {
      return
    }
    railFrame.firstChild.firstChild.appendChild(el)
    writeRailStructureStyles(this.slot.model, this.slot.adBoxConfig)
    this.railStructureApplied = true
  }
}

function createElement(elType, attributes?): HTMLElement {
  const dummyDiv = document.createElement('div')
  dummyDiv.innerHTML = `<${elType}></${elType}>`
  const el = dummyDiv.firstChild as HTMLElement
  Object.keys(attributes || {}).map((k) => el.setAttribute(k, attributes[k]))

  return el
}

const contentDots = '\\00B7\\00B7\\00B7'

const writeRailStructureStyles = doOnce(
  (model: WebModel, adBoxConfig?: AdBoxConfig) =>
    writeCss(getStyles(model, adBoxConfig))
)

function getStyles(
  {
    ad_box_bg_color,
    mobile_header_offset = 0,
    sidebar_btf_sticky_offset = 0
  }: WebModel,
  adBoxConfig?: AdBoxConfig
) {
  const baseOffset = Device.isMobile
    ? mobile_header_offset
    : sidebar_btf_sticky_offset
  const offset = baseOffset + topBuffer
  const maxHeight = adBoxConfig?.heightPxToContain || railFrameDefaultHeight
  return `
.${railFrameSelector} {
  position:relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height:${maxHeight}px;
  margin: 0px 0px;
  clear: both;
  background-color: ${ad_box_bg_color};
}

.${railSlideSelector} {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  width: 100%; 
  height: 100%;
  margin-top: ${topBuffer}px;
}

@media (max-width: 350px) {
  .${railSlideSelector} {
    padding-bottom: 15px;
  }
}

.${railStickySelector} {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-content: center;
  width: ${maxAdWidth}px;
  height: ${maxHeight}px;
  min-height: 50px;
  top: ${offset}px;
}

.${railStickySelector}>.adunitwrapper:before {
  content: "${contentDots}";
  position: absolute;
  display: flex!important;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #C4C4C4;
  font-size: 62px;
  letter-spacing: 2px;
  z-index: 0;
  animation: fadeOut 1s linear 0s infinite;
  animation-direction: alternate;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.${railStickySelector}>.adunitwrapper {
  height: initial !important;
  overflow: hidden !important;
  padding: 0;
  margin: 0;
  z-index: 1;
}

.${railStickySelector} #arrival_wrapper {
  margin: 0 !important;
}

.${railStickySelector} .adunit {
  z-index: 1;
}

.content-container {
  overflow-x: initial!important;
}

${getGrowStyleHack(offset)}
${getCreateArrivalStyleHack(maxHeight)}
`
}

// NOTE: we would not want to have these long term
function getGrowStyleHack(offset: number): string {
  return `body.grow-me-scroll-carousel-active .${railStickySelector} {
    top: ${offset + 60}px;
  }`
}

const arrivalSlotBuffer = 55
function getCreateArrivalStyleHack(maxHeight: number) {
  return `.mv-pre-create-target[style*=height] {
    height: ${maxHeight + arrivalSlotBuffer}px !important;
    width: 100% !important;
  }`
}

export const getRailFrameBySlot = (slot: Slot): HTMLElement | null =>
  document.querySelector(
    `.${railFrameSelector}[data-slotid="${slot.id}"]`
  ) as HTMLElement | null

export const getRailSlideBySlot = (slot: Slot): HTMLElement | null =>
  document.querySelector(
    `.${railSlideSelector}[data-slotid="${slot.id}"]`
  ) as HTMLElement | null

export const getRailStickyBySlot = (slot: Slot): HTMLElement | null =>
  document.querySelector(
    `.${railStickySelector}[data-slotid="${slot.id}"]`
  ) as HTMLElement | null
